window.addEventListener('load', () => {
    const geoTarget = document.getElementById('geo_target_notice')
    const allButtons = document.querySelectorAll('.js-geo-button')

    if (geoTarget && getCookieValue('regionModal') !== '1') {
        geoTarget.style.display = ''
    }

    if (allButtons.length) {
        for (const button of allButtons) {
            button.addEventListener('click', function (event) {
                let expiryDate = new Date();
                expiryDate.setMonth(expiryDate.getMonth() + 1);
                document.cookie = 'regionModal' + '=1; expires=' + expiryDate.toGMTString();
            })
        }
    }
})

function getCookieValue (a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}



